.chq-atc {
  font-family: Lato, 'Helvetica Neue', Arial, Helvetica, sans-serif;
  width: 100%;
}

.chq-atc--button {
  display: block;
  text-align: center;
  color: #1b1c1d !important;
  width: 100%;
  padding: 10px;
  border: 1px solid black;
  border-radius: 0;
}

.chq-atc--button:hover {
  background-color: unset !important;
}

svg path {
  fill: #1b1c1d !important;
}

.chq-atc--dropdown {
  margin-top: 1px;
  border-radius: 0;
  border-color: #a8a8a8;
}

.chq-atc--dropdown a {
  color: #1b1c1d;
  font-size: 14px;
  padding: 14px 20px;
}
